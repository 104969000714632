import { useAppDispatch } from "../../../redux/hooks";
import {
  FunctionComponent,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
} from "react";
import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  DrawerContainer,
  CloseContainer,
  Close,
  ListContainer,
  ItemText,
  Container,
  Button,
  Logo,
  ContainerLogos,
  ContainerCuenta,
} from "./styles";
import { Divider } from "@mui/material";
import CloseImage from "../../../assets/images/Menu/cancelButton.svg";
import FundacionLogo from "../../../assets/images/Menu/fundacionLogo.svg";
import ArcorLogo from "../../../assets/images/Header/LogoArcor.svg";
import { Avatar } from "../Avatar";
import { setEdit } from "../../../redux/reservas/editReservaSlice";
import { setInitiState } from "../../../redux/reservas/reservasSlice";

type Props = {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
};

export const MenuMobile: FunctionComponent<Props> = ({
  setOpen,
  open,
}: Props) => {
  const { token } = useAppSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const handleExpClick = useCallback(() => {
    setOpen(false);
    if (pathname === "/") {
      const scroll = document.getElementById("containerExperiencia");
      scroll?.scrollIntoView();
    } else {
      navigate("/");
    }
  }, [pathname, navigate, setOpen]);

  const handleRecorridoRapidoClick = useCallback(() => {
    setOpen(false);
    navigate("/recorridorapido");
  }, [navigate, setOpen]);

  const items = useMemo(
    () => [
      {
        name: "LA EXPERIENCIA",
        onClick: handleExpClick,
      },
      {
        name: "RESERVA TU VISITA",
        src: `${token ? "/reserva-tour" : "/auth/register"}`,
      },
      {
        name: "RECORRIDO RÁPIDO",
        onClick: handleRecorridoRapidoClick,
      },
      {
        name: "QUIENES SOMOS",
        src: "/quienes-somos",
      },
      {
        name: "CONTACTO",
        src: "/contacto",
      },
    ],
    [handleExpClick, handleRecorridoRapidoClick, token]
  );

  const handleResetState = (url?: string) => {
    dispatch(setEdit());
    dispatch(setInitiState());
    // dispatch(setInitialState());
    if (url) {
      navigate(url);
    }
  };

  return (
    <DrawerContainer
      variant="temporary"
      anchor="left"
      open={open}
      transitionDuration={700}
    >
      <Container>
        <CloseContainer>
          <Close
            alt="Close Button"
            src={CloseImage}
            onClick={() => setOpen(false)}
          />
        </CloseContainer>
        <ListContainer>
          {items.map((element, index) => (
            <ItemText key={index}>
              {element.onClick ? (
                <div style={{ cursor: "pointer" }} onClick={element.onClick}>
                  {element.name}
                </div>
              ) : (
                <Link
                  to={element.src!}
                  style={{ textDecoration: "none", color: "#000" }}
                  onClick={() => {
                    handleResetState();
                    setOpen(false);
                  }}
                >
                  {element.name}
                </Link>
              )}
            </ItemText>
          ))}
          {token ? (
            <ContainerCuenta>
              <Avatar />
              <ItemText onClick={() => handleResetState("/user/profile")}>
                MI CUENTA
              </ItemText>
            </ContainerCuenta>
          ) : (
            <Button
              onClick={() => handleResetState("/auth/register")}
              // onClick={() => handleResetState('/comingsoon')}
            >
              INGRESA
            </Button>
          )}
        </ListContainer>
        <ContainerLogos>
          <img
            alt="Logo Arcor"
            src={ArcorLogo}
            style={{
              marginRight: "17px",
              width: "100.13px",
              height: "69.07px",
            }}
          />
          <Divider
            flexItem
            orientation="vertical"
            sx={{ backgroundColor: "#3366CC", width: "2px" }}
          />
          <Logo alt="Logo Fundacion" src={FundacionLogo} />
        </ContainerLogos>
      </Container>
    </DrawerContainer>
  );
};
