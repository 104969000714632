import { Container, Image, Texto } from "./styles";
import ImageComing from "../../assets/images/auth/ImageRegister.svg";

export const ConfirmTour = () => {
  return (
    <Container>
      <Image src={ImageComing} alt="Imagen con frutas" />
      <Texto>
        Experiencia Confirmada. <br />
        Los esperamos. <br />
        ¡Muchas Gracias! <br />
      </Texto>
    </Container>
  );
};
