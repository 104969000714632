import Placeholder from "../../assets/images/Materials/placeholder.png";
import PlaceholderSmall from "../../assets/images/Materials/placeholder_small.png";
import SEC1Thumb from "../../assets/images/Materials/thumbnails/SEC-1-Thumb.png";
import SEC2Thumb from "../../assets/images/Materials/thumbnails/SEC-2-Thumb.png";
import SEC3Thumb from "../../assets/images/Materials/thumbnails/SEC-3-Thumb.png";
import SEC4Thumb from "../../assets/images/Materials/thumbnails/SEC-4-Thumb.png";
import SEC5Thumb from "../../assets/images/Materials/thumbnails/SEC-5-Thumb.png";
import SEC6Thumb from "../../assets/images/Materials/thumbnails/SEC-6-Thumb.png";
import SEC7Thumb from "../../assets/images/Materials/thumbnails/SEC-7-Thumb.png";
import SEC8Thumb from "../../assets/images/Materials/thumbnails/SEC-8-Thumb.png";
import SEC9Thumb from "../../assets/images/Materials/thumbnails/SEC-9-Thumb.png";
import SEC10Thumb from "../../assets/images/Materials/thumbnails/SEC-10-Thumb.png";
const SEC1 = "/pdfs/SEC-1.pdf";
const SEC2 = "/pdfs/SEC-2.pdf";
const SEC3 = "/pdfs/SEC-3.pdf";
const SEC4 = "/pdfs/SEC-4.pdf";
const SEC5 = "/pdfs/SEC-5.pdf";
const SEC6 = "/pdfs/SEC-6.pdf";
const SEC7 = "/pdfs/SEC-7.pdf";
const SEC8 = "/pdfs/SEC-8.pdf";
const SEC9 = "/pdfs/SEC-9.pdf";
const SEC10 = "/pdfs/SEC-10.pdf";

export const data = {
  history:[
    {
      image: SEC1Thumb,
      pdfLink: SEC1,
      title:
        "El plato de entrada a la humanidad.",
    },
    {
      image: SEC2Thumb,
      pdfLink: SEC2,
      title:
        "¿Se cocinó la evolución?",
    },
    {
      image: SEC3Thumb,
      pdfLink: SEC3,
      title:
        "Sembrando la civilización del futuro.",
    },
    {
      image: SEC4Thumb,
      pdfLink: SEC4,
      title:
        "Transformaciones a todo vapor.",
    },
    {
      image: SEC5Thumb,
      pdfLink: SEC5,
      title:
        "El idioma de los alimentos.",
    },
  ],
  process:[
    {
      image: SEC6Thumb,
      pdfLink: SEC6,
      title:
        "Alimentos con sabor a provincia.",
    },
    {
      image: SEC7Thumb,
      pdfLink: SEC7,
      title:
        "Del campo a la salsa.",
    },
    {
      image: SEC8Thumb,
      pdfLink: SEC8,
      title:
        "Un surtido de producción.",
    },
    {
      image: SEC9Thumb,
      pdfLink: SEC9,
      title:
        "De la fábrica al mundo.",
    },
    {
      image: SEC10Thumb,
      pdfLink: SEC10,
      title:
        "Mucho más que un envase.",
    },
  ],
};