import { Container, Content, MainText, MainImage } from "./styles";
import title from "../../assets/images/titulo-recorridorapido.svg";
import mainImage from "../../assets/images/recorrido-rapido.png";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4"

const url = process.env.REACT_APP_TOUR_URL;

const PreRecorridoRapido = () => {
  const handleActionClick = useCallback(() => {
    ReactGA.event({
      category: "User Actions",  
      action: "Clicked Recorrido Rápido", 
      label: "recorrido_rapido_button",
    })
    window.open(url, "_blank");
  }, []);

  return (
    <Container>
      <Content>
        <img className="title" src={title} alt="Pre Recorrido" loading="lazy" />

        <MainText>
          <p>
            Les damos la bienvenida al recorrido rápido por nuestra línea de bon
            o bon en Arcor Colonia Caroya, Córdoba.
          </p>
          <p>Hacé click y sumergite en este mundo.</p>
          <p>
            Si contás con gafas de Realidad Virtual, ¡Es tu momento para
            usarlas!
            <span>
              Disfrutá la mejor experiencia navegando con Google Chrome.
            </span>
          </p>
        </MainText>

        <MainImage>
          <img
            src={mainImage}
            onClick={handleActionClick}
            alt="Pre Recorrido"
            loading="lazy"
          />
        </MainImage>

        <MainText>
          <p>
            Para escuelas e instituciones diseñamos una experiencia junto con un
            guía que los lleva en vivo paso a paso por nuestras fábricas.{" "}
            <Link to="/auth/register">Ingresá acá.</Link>
          </p>
        </MainText>
      </Content>
    </Container>
  );
};

export default PreRecorridoRapido;
