import { Texto, TextoResaltado, NeneTomate, NenaBicicleta, Container } from './styles';
import UnviersoArcor from '../../../../assets/images/Home/UniversoArcor.svg';
import Tomate from '../../../../assets/images/Home/neneTomate.svg';
import nenaBici from '../../../../assets/images/Home/Nenabici.svg';




export const Description = () => {
    return (
        <Container>
            <NeneTomate
                alt='Nene con tomate'
                src={Tomate}
            />
            <img
                alt='Universo Arcor'
                src={UnviersoArcor}
                width='193px'
                style={{ margin: '2rem 0px 8px 0px' }}
            />
            <Texto>Universo Arcor propone a <TextoResaltado>escuelas</TextoResaltado> conocer nuestros principales procesos productivos a través de un recorrido virtual en 360° por nuestras plantas industriales. Un guía de Arcor los llevará paso a paso en este recorrido mágico, pudiendo interactuar, hacer preguntas, aprender, jugar y mucho más.
            </Texto>
            <Texto>
            Además, podrán conocer la historia de los alimentos a través de contenidos lúdicos que contaron con la asesoría de Melina Furman, Dra. en Educación y Bióloga.
            </Texto>
            <NenaBicicleta
                alt='Nena en bicicleta'
                src={nenaBici}
            />
        </Container>
    )
}